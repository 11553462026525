<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '750px' }"
    header="DETALLE PRODUCTOS"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="codigo_barra"
            ><strong>C&oacute;digo de Barras: </strong></label
          >
          <InputText
            id="codigo_barra"
            placeholder="Código de barras/Código Interno"
            v-model="data_producto.codigo_barra"
          />
          <small class="p-invalid" v-if="errors.codigo_barra">{{
            errors.codigo_barra[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <label for="descripcion"
            ><strong>Nombre de Producto - Descripci&oacute;n: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="descripcion"
            placeholder="Descripción/Nombre del Producto"
            autofocus
            v-model="data_producto.descripcion"
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="fabrica_id"
            ><strong>Laboratorio: </strong>
            <span class="p-invalid">*</span></label
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="laboratorioSelect"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un laboratorio"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span
                    >{{ slotProps.option.nombre }} -
                    {{ slotProps.option.nombre_proveedor }}
                  </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Laboratorio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarLaboratorioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.fabrica_id">{{
            errors.fabrica_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="unidadmedida_id"
            ><strong>Unidad de Medida: </strong>
            <span class="p-invalid">*</span></label
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="unidadSelect"
              :options="unidadesmedidas"
              :filter="true"
              optionLabel="nombre"
              placeholder="Seleccione una unidade medida"
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.nombre }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Unidad de Medida Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarUnidadMedidaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.unidadmedida_id">{{
            errors.unidadmedida_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="stock_minimo"
            ><strong>Stock M&iacute;nimo: </strong></label
          >
          <InputNumber
            id="stock_minimo"
            :inputClass="'text-right'"
            v-model="data_producto.stock_minimo"
            showButtons
            :min="0"
          />
          <small class="p-invalid" v-if="errors.stock_minimo">{{
            errors.stock_minimo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="stock_maximo"
            ><strong>Stock M&aacute;ximo: </strong></label
          >
          <InputNumber
            id="stock_maximo"
            v-model="data_producto.stock_maximo"
            showButtons
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.stock_maximo">{{
            errors.stock_maximo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="stock_maximo"><strong>Precio Sugerido: </strong></label>
          <InputNumber
            id="precio_sugerido"
            placeholder="0.00"
            v-model="data_producto.precio_sugerido"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.precio_sugerido">{{
            errors.precio_sugerido[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <label for="estado"><strong>Estado: </strong></label>
          <Dropdown
            id="estado"
            v-model="data_producto.estado"
            :disabled="!data_producto.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-12">
          <label for=""><strong>Caso de Uso: </strong></label>
          <Textarea
            v-model="data_producto.caso_uso"
            :rows="2"
            placeholder="Ingrese el caso de uso del Producto"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>Campos Requeridos: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="guardarProducto"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
  <UnidadMedidaCreate
    :show="UnidadMedidaModal"
    :unidadmedida="unidadMedida"
    @closeModal="cerrarModalUnidadMedida"
    @actualizarListado="cargarUnidadesMedidas"
  ></UnidadMedidaCreate>
  <LaboratorioCreate
    :mostrar="LaboratorioModal"
    :laboratorio="laboratorio"
    @closeModal="cerrarModalLaboratorio"
    @actualizarListadoLaboratorio="cargarLaboratorios"
  ></LaboratorioCreate>
</template>

<script>
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";

export default {
  components: {
    UnidadMedidaCreate,
    LaboratorioCreate,
  },
  emits: ["closeModal", "actualizarListadoProductos"],
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  productoService: null,
  laboratorioService: null,
  unidadmedidaService: null,
  auth: null,
  data() {
    return {
      activarModal: this.mostrar,
      data_producto: this.producto,
      laboratorios: {},
      unidadesmedidas: {},
      laboratorioSelect: {},
      unidadSelect: {},
      UnidadMedidaModal: false,
      LaboratorioModal: false,
      laboratorio: { estado: { label: "Activo", value: 1 } },
      unidadMedida: { estado: { label: "Activo", value: 1 } },
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      stock_minimo: 0,
      stock_maximo: 0,
      precio_sugerido: 0,
    };
  },
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.unidadmedidaService = new UnidadmedidaService();
  },
  mounted() {
    this.cargarLaboratorios();
    this.cargarUnidadesMedidas();
  },
  methods: {
    cerrarModalLaboratorio() {
      this.LaboratorioModal = false;
      this.laboratorio = {};
    },
    cerrarModalUnidadMedida() {
      this.UnidadMedidaModal = false;
      this.unidadMedida = {};
    },
    activarLaboratorioModal() {
      this.LaboratorioModal = true;
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarUnidadMedidaModal() {
      this.UnidadMedidaModal = true;
      this.unidadMedida = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cargarLaboratorios() {
      this.laboratorioService
        .getAllLaboratorios()
        .then((response) => {
          this.laboratorios = response.fabricas;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUnidadesMedidas() {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((response) => {
          this.unidadesmedidas = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal() {
      this.errors = {};
      this.laboratorioSelect = {};
      this.unidadSelect = {};
      this.activarModal = false;
      this.$emit("closeModal");
    },
    guardarProducto() {
      this.errors = {};
      this.enviado = true;
      if (this.data_producto.id) {
        //Actualizar
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
        };

        this.productoService.actualizarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Actualizado!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      } else {
        //Registrar nuevo producto
        if (this.laboratorioSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un laboratorio",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        if (this.unidadSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar una unidad de medida",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
        };

        this.productoService.registrarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      }
    },
    findIndex(id) {
      let index = -1;
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
    },
    producto(newVal) {
      this.data_producto = newVal;

      this.laboratorioSelect = this.laboratorios.find((item) => item.id == 1);
      this.unidadSelect = this.unidadesmedidas.find((item) => item.id == 1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
